import React from 'react'
import { Routes, Route } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import NewOrder from '../../components/UserDashboard/NewOrder';
import GetPayment from '../../components/UserDashboard/GetPayment';
import MyOrder from '../../components/UserDashboard/MyOrder';
import Login from '../../components/UserDashboard/Login';
import VerifyCode from '../../components/UserDashboard/Verify';
import Policy from '../../components/UserDashboard/Policy';
import Contact from '../../components/UserDashboard/Contact';
;
const UserMain = () => {

    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={< NewOrder />} />
                <Route path="/get-payment" element={< GetPayment />} />
                <Route path="/my-orders" element={< MyOrder />} />
                <Route path="/policy" element={< Policy />} />
                <Route path="/contact" element={< Contact />} />

                <Route path="/login" element={<Login />} />
                <Route path="/verify" element={<VerifyCode />} />
            </Routes>

            <Footer />

        </>
    )


}

export default UserMain