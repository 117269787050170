
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';


const EveryWhere = () => {
    const [formData, setFormData] = useState({
        service: "",
        link: "",
        quantity: "",
        mobileNumber: localStorage.getItem('mobileUser') || "",
    });

    const [calculatedPriceToman, setCalculatedPriceToman] = useState("");
    const [services, setServices] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedService, setSelectedService] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const [showMobileInput, setShowMobileInput] = useState(localStorage.getItem('mobileUser') === null);

    useEffect(() => {
        const fetchServices = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/service/all-services-cat`);
                if (response.data.success) {
                    setCategories(response.data.servicesWithCategories);
                    setSelectedCategory(response.data.servicesWithCategories[0]?.category_id);
                    setServices(response.data.servicesWithCategories[0]?.services || []);
                    // Reset the selected service when categories change
                    setSelectedService("");
                }
            } catch (error) {
                console.error("Error fetching services:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchServices();

        const storedMobileNumber = localStorage.getItem('mobileUser');
        if (storedMobileNumber) {
            setFormData((prevData) => ({ ...prevData, mobileNumber: storedMobileNumber }));
            setShowMobileInput(false);
        }
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === "category") {
            setSelectedCategory(value);
            setSelectedService(""); // Reset the selected service when the category changes
    
            // Fetch services for the selected category
            const selectedCategoryServices = categories.find((category) => category.category_id === parseInt(value))?.services || [];
            setServices(selectedCategoryServices);
    
            // Ensure the correct value is set for the category in the formData
            setFormData((prevData) => ({ ...prevData, [name]: value, service: "" }));
        } else if (name === "service") {
            setSelectedService(value);
    
            // Ensure the correct value is set for the service in the formData
            const selectedService = services.find((service) => String(service.japId) === value);
    
            const newValue = selectedService ? String(selectedService.japId) : "";
    
            setFormData((prevData) => ({ ...prevData, service: newValue }));
        } else if (name === "quantity") {
            // Convert Persian numbers to English numbers
            const englishValue = value.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
    
            setFormData((prevData) => ({ ...prevData, [name]: englishValue }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };
    
    

    useEffect(() => {
        if (formData.service && formData.quantity) {
            const selectedService = services.find((service) => service.japId == formData.service);
            if (selectedService) {
                const priceToman = (parseInt(formData.quantity) * selectedService.priceToman) / 1000;
                setCalculatedPriceToman(priceToman.toString());
            }
        }
    }, [formData.service, formData.quantity, services]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsSubmitting(true);
            const { data } = await axios.post(`${process.env.REACT_APP_URL}/order/new-order`, {
                ...formData,
                priceToman: calculatedPriceToman,
            });

            if (data.response.status === 100) {
                window.location.href = data.response.url;
            } else {
                console.log("Order submission failed");
            }
        } catch (error) {
            toast.error(error.response.data.message, { autoClose: 15000 });

        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className="form-order br-10">

                        <div className='mb-2 fw-700'>دسته‌بندی سرویس</div>
                        <select
                            name="category"
                            className="input-form-dashboard-step mb-20"
                            onChange={handleChange}
                            value={selectedCategory} // Ensure the selected category is controlled by React
                            required
                        >
                            <option value="" disabled selected>یکی از سرویسها را انتخاب کنید</option>
                            {categories.map((category) => (
                                <option key={category.category_id} value={category.category_id}>
                                    {category.categoryName}
                                </option>
                            ))}
                        </select>


                        <div className='mb-2 fw-700'>نوع سرویس</div>
                        <select
                            name="service"
                            className="input-form-dashboard-step mb-20"
                            onChange={handleChange}
                            value={selectedService} // Ensure the selected service is controlled by React
                            required
                        >
                            <option value="" disabled selected>نوع سرویس را مشخص کنید</option>
                            {services.map((service) => (
                                <option key={service.id} value={service.japId}>
                                    {service.serviceName}
                                </option>
                            ))}
                        </select>

                        <div className='mb-2 fw-700'>لینک</div>
                        <input type="text" onChange={handleChange} name="link" className="input-form-dashboard-step mb-20" placeholder='لینک پست' />

                        <div className='mb-2 fw-700'>تعداد</div>
                        <input type="text" onChange={handleChange} name="quantity" className="input-form-dashboard-step" placeholder='تعداد' />
                        {services.map((service) => (
                            service.japId == formData.service && (
                                <small key={service.id} className="form-order-small">
                                    کمترین: {service.minQuantity} - بیشترین: {service.maxQuantity}
                                </small>
                            )
                        ))}

                        {showMobileInput && (
                            <>
                                <div className='mt-20 mb-2 fw-700'>شماره تماس</div>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    className="input-form-dashboard-step"
                                    placeholder='شماره تماس'
                                />
                                <small className="form-order-small">با وارد کردن شماره موبایل، میتوانید از پنل کاربری سفارشات خود را ببینید.</small>
                            </>
                        )}
                        <div className='flex justify-between items-center  mt-20'>

                            <div>
                                <span className="badge-success p-5 fw-700 br-5">{calculatedPriceToman} تومان</span></div>
                            <button type="button" className="btn btn-primary p-10 fw-700 br-5" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال انتقال به صفحه پرداخت...' : 'ثبت سفارش'}</button>
                        </div>
                    </div>
                </>
            )}
        </>

    );
};

export default EveryWhere;
